import '../../styles/Home/Hero.scss';


export default function Hero() {
  return (
    <div className="hero" id="hero">
      <h2 className="hero-title">What we stand for?</h2>
      <h1 className="hero-subtitle">
        Global Resources <br />
        for Integrated Growth
      </h1>
    </div>
  )
}