import '../styles/components/MaxWidthContainer.scss';


export default function MaxWidthContainer(props) {
  const { children, height } = props;

  return (
    <div
      className="max-width-container"
      style={{ height }}
    >
      {children}
    </div>
  );
}
