import '../../styles/Home/OurServices.scss';
import ventoryLogo from '../../assets/shared/ventory-logo.png';


export default function OurServices() {
  return (
    <div className="our-services" id="our-services">
      <h2 className="services-title">Our Services</h2>

      <div className="services-content">
        <div className="ventory-logo-container">
          <div className="ventory-logo-inner">
            <img src={String(ventoryLogo)} alt="ventory logo" />
          </div>
        </div>

        <div className="services-description">
          <p className="description">
            Ventory helps shopkeepers easily manage inventory and create
            digital menus so customers can scan and order effortlessly. With
            our IoT devices, shop management is simpler and more efficient than
            ever. Ventory makes running your business smoother and boosts
            customer convenience.
          </p>

          <button className="learn-more-btn" onClick={() => window.open('https://ventory.in', '_blank')}>
            <p className='btn-text'>Learn More</p>
            <i className="fa-solid fa-up-right-from-square icon"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
