export const request = async (url, { method, headers, body }) => {
  const config = {};

  // populate the config with method, header and body
  if (method) { config.method = method; }
  if (headers) { config.headers = { ...config.headers, ...headers }; }
  if (body) { config.body = body; }

  try {
    // fetch request
    const response = await fetch(url, config);
    if (response.ok) {
      try {
        const data = await response.json();
        return { data: data, success: true, status: response.status };
      } catch (error) {
        return { success: true, status: response.status };
      }
    }
    return { success: false, status: response.status };
  } catch (error) {
    return { success: false, status: 400 };
  }
};