import '../../styles/Home/AboutUs.scss'


export default function AboutUs() {
  return (
    <div className="about-us" id="about-us">
      <div className="about-us-content">
        <h2 className="about-us-title">
          About Us
        </h2>
        <div className="bottom-container">
          <p className="about-us-subtitle">
            At GRIG Technologies, we are dedicated to designing innovative
            hardware and software solutions that address real-world challenges.
            By combining functionality with simplicity, we create products that
            enhance daily life and drive business growth. Guided by a commitment
            to quality and user-centric design, we turn ideas into impactful
            solutions. Together, we aim to shape a smarter and more connected
            future.
          </p>
        </div>
      </div>
    </div>
  )
}