import '../../styles/Home/Vision.scss'


export default function Vision() {
  return (
    <div className="vision" id="vision">
      <div className="vision-content">
        <h2 className="vision-title">
          Our Vision
        </h2>

        <div className="bottom-container">
          <p className="vision-subtitle">
            We envision a future where technology bridges gaps and creates
            lasting value for people and businesses. By focusing on innovation,
            ease of use, and sustainability, we aim to deliver solutions that
            empower individuals and contribute to a more interconnected world.
          </p>
        </div>
      </div>
    </div>
  )
}