import { useRef } from 'react';
import '../../styles/Home/index.scss';
import Hero from './Hero';
import AboutUs from './AboutUs';
import OurServices from './OurServices';
import Careers from './Careers';
import ContactUs from './ContactUs';
import { Footer, MaxWidthContainer, NavBar } from '../../components';
import Vision from './Vision';
import OurMission from './OurMission';


export default function Home() {
  const heroRef = useRef(null);
  const aboutUsRef = useRef(null);
  const ourServicesRef = useRef(null);
  const careersRef = useRef(null);
  const contactUsRef = useRef(null);

  return (
    <div className="home">
      <div className="nav-bar-container">
        <MaxWidthContainer>
          <NavBar
            heroRef={heroRef}
            aboutUsRef={aboutUsRef}
            ourServicesRef={ourServicesRef}
            careersRef={careersRef}
            contactUsRef={contactUsRef}

          />
        </MaxWidthContainer>
      </div>

      <div className="hero-container" ref={heroRef}>
        <MaxWidthContainer>
          <Hero />
        </MaxWidthContainer>
      </div>

      <div className="about-us-container" ref={aboutUsRef}>
        <MaxWidthContainer>
          <AboutUs />
        </MaxWidthContainer>
      </div>

      <div className="vision-container">
        <MaxWidthContainer>
          <Vision />
        </MaxWidthContainer>
      </div>

      <div className="our-mission-container">
        <MaxWidthContainer>
          <OurMission />
        </MaxWidthContainer>
      </div>

      <div className="our-services-container" ref={ourServicesRef}>
        <MaxWidthContainer>
          <OurServices />
        </MaxWidthContainer>
      </div>

      <div className="careers-container" ref={careersRef}>
        <MaxWidthContainer>
          <Careers />
        </MaxWidthContainer>
      </div>

      <div className="contact-us-container" ref={contactUsRef}>
        <MaxWidthContainer>
          <ContactUs />
        </MaxWidthContainer>
      </div>

      <div className="footer-container">
        <MaxWidthContainer>
          <Footer />
        </MaxWidthContainer>
      </div>
    </div>
  );
}
