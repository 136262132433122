import { useEffect, useRef } from 'react';
import '../styles/components/NavBar.scss';
import GrigLogo from './GrigLogo';

export default function NavBar({ heroRef, aboutUsRef, ourServicesRef, careersRef, contactUsRef }) {
  const toggleBtnRef = useRef(null);
  const navBarCenterRef = useRef(null);

  useEffect(() => {
    const toggleBtn = toggleBtnRef.current;
    const onToggleClick = () => {
      if (navBarCenterRef.current.style.display === 'flex') {
        navBarCenterRef.current.style.display = 'none';
      } else {
        navBarCenterRef.current.style.display = 'flex';
      }
    };

    toggleBtn?.addEventListener('click', onToggleClick);

    return () => {
      toggleBtn?.removeEventListener('click', onToggleClick);
    };
  }, []);

  const scrollToSection = (sectionRef, yOffset) => {
    if (sectionRef.current) {
      const y = sectionRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });

      if (window.innerWidth <= 1024) {
        navBarCenterRef.current.style.display = 'none';
      }
    }
  };

  return (
    <nav className="nav-bar">
      <div className="nav-bar-left">
        <button className="home-btn" onClick={() => scrollToSection(heroRef, -210)}>
          <GrigLogo />
        </button>
      </div>

      <div className="nav-bar-center" ref={navBarCenterRef}>
        <button className="home-btn" onClick={() => scrollToSection(heroRef, -210)}>
          <p>Home</p>
        </button>

        <button className="about-us-btn" onClick={() => scrollToSection(aboutUsRef, -100)}>
          <p>About Us</p>
        </button>

        <button className="our-services-btn" onClick={() => scrollToSection(ourServicesRef, -100)}>
          <p>Our Services</p>
        </button>

        <button className="careers-btn" onClick={() => scrollToSection(careersRef, -100)}>
          <p>Careers</p>
        </button>

        <button className="contact-us-hidden" onClick={() => scrollToSection(contactUsRef, -100)}>
          <p>Contact Us</p>
        </button>
      </div>

      <div className="nav-bar-right">
        <button className="contact-us-btn" onClick={() => scrollToSection(contactUsRef, -100)}>
        <p>Contact Us</p>
        </button>
        <button className="toggle-btn" ref={toggleBtnRef}>
          <i className="fa-solid fa-bars icon"></i>
        </button>
      </div>
    </nav>
  );
}