import { request } from './request';


export const submitForm = (formData) => {
  const url = 'https://formbold.com/s/oaKed';

  return request(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData)
  });
};