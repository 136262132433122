import { useNavigate } from 'react-router-dom';
import '../styles/components/GrigLogo.scss';
import grigFullLogo from '../assets/shared/full-logo.svg';


export default function GrigLogo() {
  const navigate = useNavigate();

  return (
    <div className="grig-logo"
      onClick={() => navigate("/")}
    >
      <img src={grigFullLogo} alt="grig logo" />
    </div>
  );
}
