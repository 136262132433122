import '../../styles/Home/ContactUs.scss';
import { submitForm } from '../../api'
import { useState } from 'react';


export default function ContactUs() {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      alert("Email is required.");
      return false;
    }
    if (!emailRegex.test(formData.email)) {
      alert("Enter a valid email address.");
      return false;
    }

    // Subject validation
    if (!formData.subject) {
      alert("Subject is required.");
      return false;
    }

    // Message validation
    if (!formData.message) {
      alert("Message is required.");
      return false;
    }
    if (formData.message.length < 30) {
      alert("Message must be at least 30 characters long.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return; // Exit if validation fails
    }

    try {
      const response = await submitForm(formData);
      if (response.success) {
        alert('Form submitted successfully!');
      } else {
        alert('Failed to submit the form.');
      }
    } catch (error) {
      alert('Failed to submit the form.');
    }
  };

  return (
    <div className='contact-us' id="contact-us">
      <div className="left-container">
        <div className="title">
          <h2>Contact Us</h2>
        </div>

        <div className="content">
          <p>Have a question or want to learn more about our services?</p>
        </div>
      </div>

      <div className="right-container">
        <div className="contact-us-form">
          <div className="title-box">
            <p>Get in touch</p>
          </div>

          <form className="form" encType="multipart/form-data">
            <input className="form-input" type="email" placeholder="Email" name="email" value={formData.email}
                   onChange={handleInput} required />

            <input className="form-input" type="text" placeholder="Subject" name="subject" value={formData.subject}
                   onChange={handleInput} required />

            <textarea className="form-textarea" placeholder="Type your message" name="message" value={formData.message}
                      onChange={handleInput}></textarea>
          </form>

          <button className="form-button" type="submit" onClick={handleSubmit}><p>Send</p>
            <i className="fa-solid fa-paper-plane icon"></i>
          </button>
        </div>
        <button className="location"
                onClick={() => window.open('https://maps.app.goo.gl/5V99RFqTbCNA8QXo6', '_blank')}>
          <i className="fa-solid fa-location-dot icon"></i>

          <p>Hyderabad, Telangana, India</p>
        </button>
      </div>
    </div>
  );
}