import '../../styles/Home/Careers.scss'
import spaceStation from '../../assets/Home/space-station.png';


export default function Careers() {
  return (
    <div className="careers" id="careers">
      <div className="top-container">
        <div className="careers-title-bar">
          <h2 className="title">
          Careers
          </h2>
        <img className="space-station" src={String(spaceStation)} alt="space-station"/>
        </div>

        <p className="careers-subtitle">
          We’re cultivating a dynamic culture where passionate innovators like
          you can thrive.
          <br/>
          If you’re eager to grow your career and create
          impactful solutions that empower businesses and individuals,
          you’re exactly where you need to be.
        </p>
      </div>
      <div className="bottom-container">
        <button className="careers-button" onClick={() => window.open('https://www.linkedin.com/company/ventory-in', '_blank')}>
          <i className="fa-brands fa-linkedin icon"></i>

          <p>See All Open Positions</p>
        </button>
      </div>
    </div>
  )
}