import '../../styles/Home/OurMission.scss';

export default function OurMission() {
  return (
    <div className="our-mission" id="our-mission">
      <div className="our-mission-content">
        <h2 className="our-mission-title">
          Our Mission
        </h2>

        <div className="bottom-container">
          <div className="info-boxes">
            <p className="info-title">
              Innovation
            </p>
            <p className="info-description">
              We lead with creativity, constantly exploring new frontiers
              to develop solutions that inspire and revolutionize industries.
            </p>
          </div>

          <div className="info-boxes">
            <p className="info-title">
              Adaptability
            </p>
            <p className="info-description">
              We embrace change as an opportunity, evolving with
              agility to meet the dynamic needs of a fast-paced world.
            </p>
          </div>

          <div className="info-boxes">
            <p className="info-title">
              Simplicity
            </p>
            <p className="info-description">
              We design technology that’s intuitive and user-friendly, making
              advanced solutions accessible and efficient for everyone.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
