import '../styles/components/Footer.scss';
import GrigLogo from './GrigLogo';
import VentoryLogo from '../assets/shared/ventory-icon.svg';



export default function Footer() {
  const scrollToSection = (sectionId, yOffset) => {
    const section = document.getElementById(sectionId);
    const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <div className="footer">
      <div className="footer-content-top">
        <div className="footer-content-left">
          <button className="home-btn" onClick={() => scrollToSection('hero', -210)}>
            <GrigLogo className="grig-logo" />
          </button>

          <div className="socials">
            <button className="linkedin-btn" onClick={() => window.open('https://www.linkedin.com/', '_blank')}>
              <i className="fa-brands fa-linkedin icon"></i>
            </button>

            <button className="x-btn" onClick={() => window.open('https://x.com/', '_blank')}>
              <i className="fa-brands fa-x-twitter icon"></i>
            </button>

            <button className="ventory-btn" onClick={() => window.open('https://ventory.in/', '_blank')}>
              <img src={String(VentoryLogo)} alt="ventory-logo"/>
            </button>
          </div>
        </div>

        <div className="footer-content-right">
          <div className="footer-links">
            <div className="footer-links-col">
              <h3>Company</h3>
              <button className="about-us-btn" onClick={() => scrollToSection('about-us', -100)}>
                <p>About Us</p>
              </button>
              <button className="our-services-btn" onClick={() => scrollToSection('our-services', -100)}>
                <p>Our Services</p>
              </button>
            </div>

            <div className="footer-links-col">
              <h3>Community</h3>
              <button className="careers-btn" onClick={() => scrollToSection('careers', -100)}>
                <p>Careers</p>
              </button>
              <p>Blogs</p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-content-bottom">
        <p>©2024 Grig Technologies Pvt Ltd.</p>
      </div>
    </div>
  );
}