import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from './Home';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="*"
          element={<Navigate to="/"/>}
        />
      </Routes>
    </BrowserRouter>
  );
}